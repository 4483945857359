<template>
  <main class="register-page flex justify-center items-center h-screen">
    <div
      class="xl:w-2/3 lg:w-2/3 md:w-3/4 sm:w-11/12 rounded shadow-card grid xl:grid-cols-2 lg:grid-cols-2"
    >
      <div class="image-and-logo bg-light-gray">
        <div class="logo-images flex justify-center items-center my-8">
          <img
            src="@/assets/images/online-icon.png"
            alt="Xlinx Logo"
            class="mr-2 align-top"
          />
          <img
            src="@/assets/images/prolope-icon.svg"
            alt="Xlinx Logo"
            class="mr-2 image2 mt-2"
          />
          <img
            src="@/assets/images/group.svg"
            alt="Xlinx Logo group"
            class="mr-2 image3 align-baseline mt-2"
          />
        </div>

        <div class="register-icon mx-auto">
          <img
            src="@/assets/images/register-image.svg"
            alt="Register Image"
            class="w-full h-full"
          />
        </div>
      </div>

      <div class="font-sans p-8 text-left">
        <div class="mb-8">
          <h4 class="text-2xl font-bold">
            {{ $t("heading.main.pages.register.account") }}
          </h4>
          <p class="mt-2 text-dark-gray">
            {{ $t("text.main.pages.register.message") }}
          </p>
        </div>

        <form
          action="#"
          @submit.prevent="register"
          @keyup="removeError($event.target.name)"
          @change="removeError($event.target.name)"
        >
          <div class="form-group pb-3">
            <base-input
              name="name"
              :placeholder="$t('label.main.pages.register.full_name')"
              :class="[
                !!errors.name
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500'],
              ]"
              v-model="user.name"
            />

            <label
              for="name"
              class="text-xs"
              :class="!!errors.name ? 'text-red-900' : 'text-gray-500'"
            >
              {{
                !!errors.name
                  ? errors.name[0]
                  : $t("label.main.pages.register.full_name")
              }}
            </label>
          </div>

          <div class="form-group pb-3">
            <base-input
              type="email"
              name="email"
              :placeholder="$t('placeholder.main.pages.register.email')"
              :class="[
                !!errors.email
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500'],
              ]"
              v-model="user.email"
            />

            <label
              for="email"
              class="text-xs text-gray-500"
              :class="!!errors.email ? 'text-red-900' : 'text-gray-500'"
            >
              {{
                !!errors.email
                  ? errors.email[0]
                  : $t("label.main.pages.register.email")
              }}
            </label>
          </div>

          <div class="form-group pb-3">
            <base-input
              type="password"
              name="password"
              :placeholder="$t('label.main.pages.register.password')"
              :class="[
                !!errors.password
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500'],
              ]"
              v-model="user.password"
            />

            <label
              for="password"
              class="text-xs"
              :class="!!errors.password ? 'text-red-900' : 'text-gray-500'"
            >
              {{
                !!errors.password
                  ? errors.password[0]
                  : $t("label.main.pages.register.password")
              }}
            </label>
          </div>

          <div class="form-group pb-3">
            <base-input
              type="password"
              name="password_confirmation"
              :placeholder="$t('label.main.pages.register.confirm_password')"
              id="password_confirmation"
              :class="[
                !!errors.password_confirmation
                  ? ['border-red-900 placeholder-red-900']
                  : ['border-light-green placeholder-gray-500'],
              ]"
              v-model="user.password_confirmation"
            />

            <label
              for="password_confirmation"
              class="text-xs"
              :class="
                !!errors.password_confirmation
                  ? 'text-red-900'
                  : 'text-gray-500'
              "
            >
              {{
                !!errors.password_confirmation
                  ? errors.password_confirmation[0]
                  : $t("label.main.pages.register.confirm_password")
              }}
            </label>
          </div>

          <div class="form-check pb-3">
            <label
              class="checkbox__container checkbox__container--block"
              for="terms"
            >
              {{ $t("label.main.pages.register.i_accept") }}
              <input
                class="answers-checkbox"
                id="terms"
                name="acceptTerms"
                type="checkbox"
                v-model="user.acceptTerms"
              />
              <span class="checkmark"></span>
            </label>

            <span class="text-red-900 text-sm" v-if="!!errors.acceptTerms">
              {{ $t("label.main.pages.register.you_accept") }}
            </span>
          </div>

          <div class="flex justify-between mt-10">
            <router-link
              class="bg-white text-dark-green border border-dark-green hover:bg-dark-green hover:text-white p-1 px-10 text-center transition ease-in duration-150"
              :to="{ name: 'login' }"
            >
              {{ $t("button.main.pages.register.login") }}
            </router-link>

            <button
              :disabled="hasErrors || loading"
              :title="
                hasErrors ? $t('valiation.pages.auth.resolve_errors') : ''
              "
              type="submit"
              class="bg-dark-green text-white p-1 px-10 text-center transition ease-in duration-100 hover:opacity-90"
              :class="{ 'cursor-not-allowed': hasErrors }"
            >
              {{ $t("button.main.pages.register.register") }}
              <font-awesome-icon
                icon="circle-notch"
                class="fa-spin"
                v-show="loading"
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "register",
};
</script>

<script setup>
import { reactive, computed, inject } from "vue";

import { useRouter } from "vue-router";
import api from "@/services/api";
import authenticate from "@/composables/auth/authenticate";
import validator from "@/composables/auth/validator";

const user = reactive({
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
});

const notify = inject("notify");
const t = inject("t");
const router = useRouter();

const { loading, registerUser, getAuth: auth } = authenticate();
const { errors, removeError, clearErrors, validate } = validator();

const hasErrors = computed(() => {
  return Object.values(errors.value).length;
});

const register = async () => {
  validate({
    data: user,
    rules: {
      name: ["required"],
      email: ["required", "unique", "email"],
      password: ["required", "between:8-20"],
      password_confirmation: ["required", "match:password"],
      acceptTerms: ["valueIn:true"],
    },
  });

  if (hasErrors.value) {
    notify({
      message: "Please fix all the errors",
      type: "danger",
    });
    return;
  }

  try {
    loading.value = true;
    const response  = await registerUser({ ...user });
    if(response.status == 200) {
      setRegisterError(response?.response?.data || t("label.main.pages.register.suceess_register"), "success");
      reset();
      redirectUser();
    } else {
      setRegisterError(response?.response?.data || t("validation.main.pages.register.error_msg"));
    }
    loading.value = false;
  } catch (e) {
    loading.value = false;
    setRegisterError();
  }
};

const setRegisterError = (message = t("validation.main.pages.register.error_msg"), type = "danger") => {
  notify({
    message,
    type,
  });
}

const reset = () => {
  clearErrors();
  clearForm();
  loading.value = false;
};

const redirectUser = () => {
  if (auth.value && !!auth.value.email_verified_at == true) {
    router.replace({ name: "Home" });
    return;
  } else {
    router.replace({ name: "BaseProfile" });
  }
};

const clearForm = () => {
  user.name = "";
  user.email = "";
  user.password = "";
  user.password_confirmation = "";
  user.acceptTerms = false;
};
</script>

<style scoped lang="scss">
.logo-images {
  .image2 {
    width: 200px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 170px;
    }
    @media (max-width: 900px) {
      width: 150px;
    }
    @media (max-width: 600px) {
      width: 120px;
    }
  }
  .image3 {
    width: 103px;
    height: 30px;
    @media (max-width: 1200px) {
      width: 90px;
    }
    @media (max-width: 900px) {
      width: 80px;
    }
    @media (max-width: 600px) {
      width: 70px;
    }
  }
}

.register-icon {
  width: 300px;
  height: 300px;
  @media (max-width: 1200px) {
    width: 250px;
    height: 250px;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}

.border-b-half {
  border-bottom-width: 0.5px;
}
</style>
